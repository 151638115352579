.ant-legacy-form-inline .ant-legacy-form-item {
  display: flex;
  margin-right: 16px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.ant-picker-input { 
  width: 129px;
}
.ant-btn{
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 5px;
}
.form{
  margin: 20px;
}
.notifications-menu {
  min-width: 300px;
  max-height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-inline-start: 18px;
}
.ant-table-thead > tr > th{
text-align: center !important;
}


.ant-table table {
text-align: center !important;
}
.ant-form-item-label,.ant-legacy-form-item-label{
text-align: start !important;
}
.ant-tabs-tab{
margin-left: 8px;
}
.ant-modal-header{
margin-right: 20px;
}
.ant-notification-notice div{
margin: 0 10px 0 10px;
}

.ant-tabs-content{
display: block !important;
margin-left: 0 !important;
}
dd {
margin-inline-start: 0px;
}